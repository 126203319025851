// Данный файл - лишь собрание подключений готовых компонентов.
// Рекомендуется создавать отдельный файл в папке components и подключать все там

// Определение операционной системы на мобильных
// import { mobileCheck } from "./functions/mobile-check";
// console.log(mobileCheck())

// Определение ширины экрана
// import { isMobile, isTablet, isDesktop } from './functions/check-viewport';
// if (isDesktop()) {
//   console.log('...')
// }

// Троттлинг функции (для ресайза, ввода в инпут, скролла и т.д.)
// import { throttle } from './functions/throttle';
// let yourFunc = () => { console.log('throttle') };
// let func = throttle(yourFunc);
// window.addEventListener('resize', func);

// Фикс фулскрин-блоков
// import './functions/fix-fullheight';

// Реализация бургер-меню
// import { burger } from './functions/burger';

// Реализация остановки скролла (не забудьте вызвать функцию)
// import { disableScroll } from './functions/disable-scroll';

// Реализация включения скролла (не забудьте вызвать функцию)
// import { enableScroll } from './functions/disable-scroll';

// Реализация модального окна
// import GraphModal from 'graph-modal';
// const modal = new GraphModal();

// Реализация табов
// import GraphTabs from 'graph-tabs';
// const tabs = new GraphTabs('tab');

// Получение высоты шапки сайта (не забудьте вызвать функцию)
// import { getHeaderHeight } from './functions/header-height';

// Подключение плагина кастом-скролла
// import 'simplebar';

// Подключение плагина для позиционирования тултипов
// import { createPopper, right} from '@popperjs/core';
// createPopper(el, tooltip, {
//   placement: 'right'
// });

// Подключение свайпера
import Swiper from 'swiper/bundle';
const swiper = new Swiper(".sliderMain", {
    loop: true,
    speed: 1000,
    spaceBetween: 20,
    autoplay: {
        delay: 7500,
        disableOnInteraction: false,
    },
    pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true,
        clickable: true,
    },
    on: {
        init() {
            this.el.addEventListener('mouseenter', () => {
                this.autoplay.stop();
            });

            this.el.addEventListener('mouseleave', () => {
                this.autoplay.start();
            });
        }
    },
});
const swiper2 = new Swiper(".sliderReviews", {
    loop: true,
    speed: 1000,
    slidesPerView: 3,
    autoplay: {
        delay: 7500,
        disableOnInteraction: false,
    },
    pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true,
        clickable: true,
    },
    breakpoints: {
        0: {
           spaceBetween: 10,
           slidesPerView: 1,
        },
        652: {
           spaceBetween: 20,
           slidesPerView: 2,
        },
        1452: {
            spaceBetween: 40,
            slidesPerView: 3,
        }
     },
    on: {
        init() {
            this.el.addEventListener('mouseenter', () => {
                this.autoplay.stop();
            });

            this.el.addEventListener('mouseleave', () => {
                this.autoplay.start();
            });
        }
    },
});

// Подключение анимаций по скроллу
// import AOS from 'aos';
// AOS.init();

// Подключение параллакса блоков при скролле
// import Rellax from 'rellax';
// const rellax = new Rellax('.rellax');

// Подключение плавной прокрутки к якорям
// import SmoothScroll from 'smooth-scroll';
// const scroll = new SmoothScroll('a[href*="#"]');

// Подключение событий свайпа на мобильных
// import 'swiped-events';
// document.addEventListener('swiped', function(e) {
//   console.log(e.target);
//   console.log(e.detail);
//   console.log(e.detail.dir);
// });

// import { validateForms } from './functions/validate-forms';
// const rules1 = [...];

// const afterForm = () => {
//   console.log('Произошла отправка, тут можно писать любые действия');
// };

// validateForms('.form-1', rules1, afterForm);

window.addEventListener('scroll', function() {
    if(window.pageYOffset != 0){
        this.document.querySelector(".header").classList.add("header--scroll")
    }
    else{
        this.document.querySelector(".header").classList.remove("header--scroll")
    }
    $(".headerMob").removeClass("headerMob--active")
    $(".btnMenu").removeClass("btnMenu--active")


    if(document.querySelector("#price")){
        var el = document.querySelector("#price");
        var inViewport = elementInViewport(el);
        console.log(inViewport)
        if(inViewport){
            $(".priceBtn.anchor").addClass("hidden")
        }
        else{
            $(".priceBtn.anchor").removeClass("hidden")
        }
    }
});

$('.btnMenu').on('click', function (e) {
    if($(this).hasClass("btnMenu--active")){
        $(".headerMob").removeClass("headerMob--active")
        $(".btnMenu").removeClass("btnMenu--active")
    }
    else{
        $(".headerMob").addClass("headerMob--active")
        $(".btnMenu").addClass("btnMenu--active")
    }
});

function inp() {
    document.querySelectorAll(".inputDecor input, .inputDecor textarea").forEach((element) => {
        element.oninput = function () {
           if (this.value.trim().length === 0) {
              this.classList.remove("act")
           }
           else {
              this.classList.add("act");
           }
        };
        if (element.value.trim().length === 0) {
           element.classList.remove("act")
        }
        else {
           element.classList.add("act");
        }
    });
}

inp()

if($(".specialists__wrapCards").length >= 1) {
    var a = document.querySelector(".specialists__wrapCards");
    mixitup(a)
}

$('[data-btn]').on('click', function (e) {
    $('[data-btn]').removeClass("active")
    $(this).addClass("active")
    $("[data-tab]").removeClass('active')
    $("[data-tab="+ $(this).attr("data-btn") +"]").addClass('active')
});

// import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";

// Fancybox.bind("[data-fancybox]", {});

$(".modalBtn").fancybox({});

$("a.anchor").click(function(e) {
    e.preventDefault();
    $("html, body").animate({
       scrollTop: $($(this).attr("href")).offset().top - 10 - $('.header').outerHeight() + "px"
    }, {
       duration: 500,
       easing: "swing"
    });
    return false;
});

function elementInViewport(el){
    var bounds = el.getBoundingClientRect();
    return (
        (window.innerHeight - bounds.top > 0)
    );
}

let cc = 10;
$('.nalog-form__add').on('click', function (e) {
    cc++
    $(".nalog-form__dop").addClass("flex")
    let html = `<div>
    <label for="name${cc}-form" class="inputDecor">
        <input type="text" name="name${cc}-form" id="name${cc}-form" class="">
        <span>ФИО пациента</span>
        <div class="inputDecor__error">Укажите ФИО пациента</div>
    </label>
</div>

<div>
    <label for="date-${cc}-form" class="inputDecor">
        <input type="text" name="date-${cc}-form" id="date-${cc}-form" class="">
        <span>Дата рождения пациента</span>
        <div class="inputDecor__error">Укажите дату рождения пациента</div>
    </label>
</div>`

    $(".nalog-form__dop").append(html)
    inp()
});

$(".faq__btn").on('click', function (e) {
    $(this).toggleClass("faq__btn--active")
    $(this).closest(".faq").find(".faq__content").slideToggle(500)
})

$('input[type=file]').on('change', function () {
    for (var i = 0; i < this.files.length; i++) {
        let file = this.files.item(i);
        $(".file span div").text(file.name)
    };
})